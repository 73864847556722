import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import model from './plugins/modal.js'
import './config/rem'//rem布局
import '@/styles/index.scss'
import $ from "jquery";
import '@/styles/common.css'


window.jQuery = $;
window.$ = $;
Vue.prototype.$EventBus = new Vue();

//挂载
Vue.prototype.$model = model;
Vue.use(ElementUI);
Vue.config.productionTip = false


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')