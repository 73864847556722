import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageType: '',
    scrollTop: 0
  },
  mutations: {
    setPageType(state, data) {
      state.pageType = data;
    },
    setScrollTop(state, data){
      state.scrollTop = data;
    }
  },
  actions: {

  }
})　　