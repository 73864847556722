<template>
	<div class="header flex-center" :class="isScorll?'headerBg':''">
		<div class="head flex-center w-100">
			<img @dragstart.prevent  class="logo" src="@/assets/logo_title.png" alt="" />
			<div class="menu flex-box">
				<div  class="item flex-column-center" v-for="(item,index) in menuList" :key="index" @click="menuClick(index)">
					<span>{{item.name}}</span>
					<span class="lines" v-show="index===chooseIdx"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				menuList:[{name:'首页',id:'indexs'},{name:'关于我们',id:'abouts'},
				{name:'运营方案',id:'programme'},{name:'积分价值',id:'jifenJz'},{name:'获取积分',id:'obtain'},{name:'联系我们',id:'contact'}],
				chooseIdx:0,
				isScorll:false,//是否滚动了
			}
		},
		watch: {
			'$store.state.scrollTop':{
				handler(newValue, oldValue) {
					if(newValue>=4550 && newValue<=4600){//获取积分
						this.chooseIdx = 4
					}else if(newValue>=2250 && newValue<=2300){//积分价值
						this.chooseIdx = 3
					}else if(newValue>=1550 && newValue<=1700){//运营方案
						this.chooseIdx = 2
					}else if(newValue>=4900 && newValue<=4968){//首页
						this.chooseIdx = 5
					}else if(newValue>=330 && newValue<=400){//关于我们
						this.chooseIdx = 1
					}else if(newValue==0){//首页
						this.chooseIdx = 0
					}

					if(newValue>300){
						this.isScorll = true
					}else{
						this.isScorll = false
					}

					this.$forceUpdate()
				},
				deep:true
			}
		},
		methods:{
			menuClick(index){
				this.chooseIdx = index;
				this.$store.commit('setPageType',index);
			}
		}
	}
</script>

<style scoped lang="scss">
	/* 禁止文字选择 */
	* {
	  -webkit-user-select: none; /* Safari 和 Chrome */
	  -moz-user-select: none; /* Firefox */
	  -ms-user-select: none; /* IE 10+ */
	  user-select: none;
	}
	.header{
		height:100%;
		width: 100%;
		background-color: transparent;
		.logo{
			width: 145px;
			height: 50px;
			margin-right: 80px;
		}
		
		.menu{
			height: 100%;
			.item{
				margin-right: 80px;
				text-align: center;
				height: 60px;
				line-height: 40px;
				font-size: 16px;
				font-weight: 900;
				cursor: pointer;
				transition: all 0.3s;
				color: #fff;
			}
			.item:last-child{
				margin-right: 0;
			}
			.item:hover{
				transform: scale(1.2);
			}
			
			.lines{
				width: 30px;
				height: 5px;
				background-color: #d08b00;
				border-radius: 10px;
			}
		}
	}

	.headerBg{
		background: #000;
	}
</style>