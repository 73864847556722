<template>
	<div id="app">
		<keep-alive>
		  <router-view></router-view>
		</keep-alive>
	</div>
</template>
<script>
	export default {
		name: 'App',
		mounted() {
		}
	}
</script>
<style>
	#app{
		min-height: 100vh;
	}
	/* 重置Swiper的层级 */
	.swiper-container .swiper-slide,
	.swiper-container .swiper-wrapper {
	  z-index: 0 !important; /* 调整为正常的层级 */
	}
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
		border-radius: 0;
	}

	::-webkit-scrollbar-thumb {
		cursor: pointer;
		border-radius: 5px;
		background: rgba(0, 0, 0, 0.15);
		transition: color 0.2s ease;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	::-moz-scrollbar {
		-webkit-appearance: none;
		width: 6px;
		height: 6px;
	}

	::-moz-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
		border-radius: 0;
	}

	::-moz-scrollbar-thumb {
		cursor: pointer;
		border-radius: 5px;
		background: rgba(0, 0, 0, 0.15);
		transition: color 0.2s ease;
	}

	::-moz-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	.el-image-viewer__actions {
		bottom: 60px !important;
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	::v-deep input[type="number"] {
		-moz-appearance: textfield !important;
	}

	.el-input-number .el-input__inner::-webkit-outer-spin-button,
	.el-input-number .el-input__inner::-webkit-inner-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}

	::v-deep.el-input input::-webkit-outer-spin-button,
	::v-deep.el-input input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	::v-deep.el-input input[type="number"] {
		-moz-appearance: textfield;
	}

	::v-deep.el-input .el-input__inner {
		line-height: 1px !important;
	}

	.el-input.is-disabled .el-input__inner,
	.el-textarea.is-disabled .el-textarea__inner {
		color: #000 !important;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	}
	input[type="number"]{
	  -moz-appearance: textfield;
	}
</style>
<style lang="scss">
	
</style>